import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import AdvancedTable from "../../components/AdvancedTable";
import {
  StringComboFilter,
  NumberComboFilter,
  SelectMultipleColumnsFilter,
} from "../../components/filterTypes/filterTypes";
import {
  apiGetCurrency,
  apiGetCountry,
  apiGetMt4Account,
  apiGetCCDetailChannelData,
  apiGetPaymentStatus,
  apiDownloadFile,
  apiGetCCDetailDownloadData,
  apiGetCCDetailData,
} from "../../resource/index.js";
import Storage from "../../common/lib/storage";
import DateRange from "../../common/lib/dateRange";
import * as types from '../../constants/actionTypes';
import {
  FUND_TYPES,
  ACCOUNT_TYPES,
  DEFAULT_CCWITHDRAWDETAIL_STATE,
} from '../../constants/constants';
import useAPIError from "../../common/hooks/useAPIError";
import theme from '../../constants/theme';
import { XSSRecover } from "../../util/xssHelper";

const useStyles = makeStyles(() => ({
  root: {
    width: (dynamicWidth) => dynamicWidth - theme.spacing(39).replace('px', ''),
  },
  topDiv: {
    display: 'flex',
    height: "500px",
    position: 'relative',
    width: (dynamicWidth) => dynamicWidth - theme.spacing(40).replace('px', ''),
  },
}));

const find = (arr, key, value) => {
  for (let item of arr) {
    if (item[key] === value) {
      return item;
    }
  }

  return null;
}

export default function CCWithdrawDetailReport() {
  const { seletedMt4AccountList, ccWithdrawDetailState } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const setCountry = (val) => dispatch({ type: types.SET_COUNTRY_LIST, value: val});
  const setMt4Account = (val) => dispatch({ type: types.SET_MT4_ACCOUNT_LIST, value: val});
  const setCCDetailChannels = (val) => dispatch({ type: types.SET_CCWITHDRAWDETAIL_CHANNEL_LIST, value: val});
  const setCCDetailStatusList = (val) => dispatch({ type: types.SET_CCWITHDRAWDETAIL_STATUS_LIST, value: val });
  const setSelectedCountry = (val) => dispatch({ type: types.SET_SELECTED_COUNTRIES, value: val});
  const setSelectedMt4Accounts = (val) => dispatch({ type: types.SET_SELECTED_MT4_ACCOUNT_LIST, value: val });
  const setSelectedCCDetailChannels = (val) => dispatch({ type: types.SET_SELECTED_CCWITHDRAWDETAIL_CHANNEL_LIST, value: val });
  const setSelectedCCDetailStatus = (val) => dispatch({ type: types.SET_SELECTED_CCWITHDRAWDETAIL_STATUS, value: val });
  const setCCWithdrawDetailState = (val) => dispatch({ type: types.SET_CCWITHDRAWDETAIL_STATE, value: val });
  const setSelectedContinents = (initState) => dispatch({ type: types.SET_CONTINENTS_LIST, value: initState });
  const setUserAndLevelName = (val) => dispatch({ type: types.SET_USER_AND_LEVEL_NAME, value: val });
  const setLevelId = (val) => dispatch({ type: types.SET_LEVEL_ID, value: val });
  const setShowAll = (val) => dispatch({ type: types.SET_SHOW_ALL, value: val });
  const { addError } = useAPIError();
  const [currency, setCurrency] = React.useState([]);
  // start table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [noData, setNoData] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [selectedSort, setSelectedSort] = React.useState({});
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [checkedUsd, setCheckedUsd] = React.useState(false);
  const regulator = Storage.getRegulator() ?? "";
  const { filters } = useSelector(state => state.report);
  const isTradingAccount = filters.accountType === ACCOUNT_TYPES.TRADING_ACCOUNT;
  const [dynamicColumns, setDynamicColumns] = React.useState([]);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Ticket ID",
          accessor: "ticket_id",
          disableSortBy: true,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Agent",
          accessor: "ib_name",
          disableSortBy: true,
          disableFilters: false,
          Filter: StringComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            XSSRecover(cell.value, "left")
          ),
        },
        {
          Header: "Client Name",
          accessor: "user_name",
          disableSortBy: true,
          disableFilters: false,
          Filter: StringComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            XSSRecover(cell.value, "left")
          ),
        },
        {
          Header: "Account",
          accessor: "mt4_account",
          disableSortBy: true,
          disableFilters: false,
          Filter: NumberComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Currency",
          accessor: "currency",
          disableSortBy: false,
          Filter: ({ column }) =>
            SelectMultipleColumnsFilter({ column }, { dropdown: currency }),
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Merchant Order",
          accessor: "merchant_order",
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Withdraw Amount",
          accessor: "withdraw_amount",
          disableSortBy: true,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Open Time",
          accessor: "create_time",
          disableSortBy: false,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Status",
          accessor: "status",
          disableSortBy: true,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Order Channel",
          accessor: "order_channel",
          disableSortBy: true,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Order PSP",
          accessor: "psp_name",
          disableSortBy: true,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            XSSRecover(cell.value, "left")
          ),
        },
        {
          Header: "Deposit Order",
          accessor: "deposit_order",
          disableSortBy: true,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Refund Amount",
          accessor: "refund_amount",
          disableSortBy: true,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "CC Processed Time",
          accessor: "transaction_time",
          disableSortBy: true,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "CC Last Update Time",
          accessor: "cc_update_time",
          disableSortBy: false,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Order Status",
          accessor: "order_status",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        ...dynamicColumns,
      ], [currency, isTradingAccount, dynamicColumns]);

  const downloadFile = (file) => {
    apiDownloadFile(file)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([
          new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
          "",
          res.data],
          { type: "text/plain;charset=utf-8" }
          ));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      })
  };

  const getDownloadData = () => {
    setLoading(true);
    apiGetCCDetailDownloadData({
      regulator: regulator,
      sort: selectedSort,
      filter: selectedFilter ? selectedFilter : {},
      from: filters.fromDate || "",
      to: filters.toDate || "",
      accountType: filters.accountType || undefined,
      countryCodes: filters.countryCodes || undefined,
      withdrawStatus: ccWithdrawDetailState.seletedCCWithdrawDetailStatus ? ccWithdrawDetailState.seletedCCWithdrawDetailStatus.map(r => r.value) : undefined || undefined,
      orderChannel: ccWithdrawDetailState.seletedCCWithdrawDetailChannelList ? ccWithdrawDetailState.seletedCCWithdrawDetailChannelList.map(r => r.value) : undefined || undefined,
      levelId: filters.levelId || "",
      showAll: filters.showAll,
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res?.data?.data?.file) {
            downloadFile(res.data.data.file);
          }
        }
      })
      .catch(() => {
        setLoading(false);
	  })
  };

  const getData = React.useCallback(async () => {
    try {
      const currency_res = (await apiGetCurrency(regulator)).data;
      setCurrency(currency_res.data.currency);

      const country_res = (await apiGetCountry(regulator)).data;
      setCountry(country_res.data.country);

      const mt4_account_res = (await apiGetMt4Account(regulator)).data.data.accountTypes;
      setMt4Account(Object.keys(mt4_account_res).map(key => { return {"label": key, "value": mt4_account_res[key]}}).filter((record) => record.value > 0 ));

      const cc_detail_channels = (await apiGetCCDetailChannelData(regulator)).data;
      setCCDetailChannels(cc_detail_channels.data.creditCardDetailChannel);

      const cc_withdraw_status_list = (await apiGetPaymentStatus(regulator, 2)).data;
      setCCDetailStatusList(cc_withdraw_status_list.data.paymentStatus.map(r => ({value: r.p_value, label: r.p_content})));
    } catch (err) {
      console.log(err);
      setMt4Account([]);
      setCurrency([]);
      setCountry([]);
      setCCDetailChannels([]);
      setCCDetailStatusList([]);
    }
  }, [regulator]);

  React.useEffect(() => {
    if (regulator) {
        getData();
    }
  }, [regulator]);


  const fetchData = ({ pageSize, pageIndex, sortParams, filterParams }) => {
    if (DateRange.FindRangeError(filters.fromDate, filters.toDate)) {
      addError(DateRange.FindRangeError(filters.fromDate, filters.toDate));
      return;
    }

    const filterOptions = {
      currency: filterParams.currency || undefined,
      agent: filterParams.ib_name || undefined,
      clientName: filterParams.user_name || undefined,
      account: filterParams.mt4_account && filterParams.mt4_account["="] ? filterParams.mt4_account["="] : (filterParams.mt4_account || undefined),
      orderNumber: filterParams.order_number || undefined,
      accounttype: seletedMt4AccountList ? seletedMt4AccountList.map(r => r.value) : undefined || undefined,
    }
    setSelectedSort(sortParams);
    setSelectedFilter(filterOptions);
    setLoading(true);
    setNoData(false);
    apiGetCCDetailData({
      regulator: regulator,
      pageNo: pageIndex + 1,
      pageSize: pageSize,
      sort: sortParams,
      filter: filterOptions ? filterOptions : {},
      from: filters.fromDate,
      to: filters.toDate,
      accountType: filters.accountType || undefined,
      countryCodes: filters.countryCodes || undefined,
      withdrawStatus: ccWithdrawDetailState.seletedCCWithdrawDetailStatus ? ccWithdrawDetailState.seletedCCWithdrawDetailStatus.map(r => r.value) : undefined || undefined,
      orderChannel: ccWithdrawDetailState.seletedCCWithdrawDetailChannelList ? ccWithdrawDetailState.seletedCCWithdrawDetailChannelList.map(r => r.value) : undefined || undefined,
      levelId: filters.levelId || "",
      showAll: filters.showAll,
    })
      .then((res) => {
        if (res.data.status === "success") {
          setData(res.data.data.cc_withdraw_detail);
          setCount(res.data.data.total);
          setPageCount(Math.ceil(res.data.data.total / pageSize));
        }else{
          console.log("[Get Deposit] status " + res.data.status);
          setNoData(true);
          setData([]);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setNoData(true);
        setData([]);
      })

  }

  const options = {};

  if (checkedUsd) {
    options.value=(node) => {
      if (checkedUsd && node && node.value && node.value.USD) {
        return node.value.USD.deposit + node.value.USD.withdraw;
      } else {
        return node;
      }
    };
    options.legends=[{
        anchor: 'bottom-left',
        direction: 'column',
        justify: true,
        translateX: 10,
        translateY: -5,
        itemsSpacing: 0,
        itemWidth: 94,
        itemHeight: 18,
        itemDirection: 'left-to-right',
        itemTextColor: '#444444',
        itemOpacity: 0.85,
        symbolSize: 18,
        effects: [{
          on: 'hover',
          style: {
            itemTextColor: '#000000',
            itemOpacity: 1
          }
        }]
    }];
  }
  const [dynamicWidth, setDynamicWidth] = React.useState(window.innerWidth);
  const classes = useStyles(dynamicWidth);

  function handleResize() {
    setDynamicWidth(window.innerWidth)
  }
  window.addEventListener('resize', handleResize)

  const resetFilter = () => {
    setSelectedCountry([]);
    setSelectedContinents([]);
    setSelectedMt4Accounts([]);
    setSelectedCCDetailChannels([]);
    setCCWithdrawDetailState(DEFAULT_CCWITHDRAWDETAIL_STATE);
    setUserAndLevelName("");
    setLevelId("");
    setShowAll(true);
  }

  return (
    <div className={classes.root}>
      <AdvancedTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        noData={noData}
        count={count}
        pageCount={pageCount}
        getDownloadData={getDownloadData}
        resetFilter={resetFilter}
      />
    </div>
  );
}
