import * as types from '../constants/actionTypes';
import {
        DEFAULT_DEPOSITATE_STATE,
        DEFAULT_TRADE_STATE,
        DEFAULT_REBATE_STATE,
        DEFAULT_FTD_STATE,
        DEFAULT_CCTRANSACTION_STATE,
        DEFAULT_CCWITHDRAWDETAIL_STATE,
} from "../constants/constants";

const initialState = {
  filters: {},
  selectedCountries: [], // selected country items
  dataSourceList: [],
  currencyList: [],
  mt4AccountList: [],
  seletedMt4AccountList: [],
  countryList: [],
  paymentStatusList: [],
  continentsList: [],
  rebateState: DEFAULT_REBATE_STATE,
  tradeState: DEFAULT_TRADE_STATE,
  deposityState: DEFAULT_DEPOSITATE_STATE,
  ftdState: DEFAULT_FTD_STATE,
  ccTransactionState: DEFAULT_CCTRANSACTION_STATE,
  ccWithdrawDetailState: DEFAULT_CCWITHDRAWDETAIL_STATE,
  userAndLevelName: "",
  levelId: "",
  showAll: true,
  mt4ServerList: [],
  seletedMt4ServerList: [],  
  actionList: [],
  seletedActionList: [],  
  selectedCreditCardChannel: "",
  selectedTransactionType: "",
  selectedCcStatus: "",
  selectedSourceDataVerifyStatus: "",
  ccWithdrawDetailChannelList: [],
  seletedCCWithdrawDetailChannelList: [],
  ccWithdrawDetailStatusList: [],
  seletedCCWithdrawDetailStatus: [],
}

export default function report(state = initialState, action) {
  switch(action.type) {
    case types.SET_FILTERS:
      return {
        ...state,
        filters: {...state.filters, ...action.filters}
      }
    case types.SET_DATA_SOURCE_LIST:
      return {
        ...state,
        dataSourceList: action.value
      }
    case types.SET_CURRENCY_LIST:
      return {
        ...state,
        currencyList: action.value
      }
    case types.SET_MT4_ACCOUNT_LIST:
      return {
        ...state,
        mt4AccountList: action.value
      }
    case types.SET_SELECTED_MT4_ACCOUNT_LIST:
      return {
        ...state,
        seletedMt4AccountList: action.value
      }
    case types.SET_MT4_SERVER_LIST:
      return {
        ...state,
        mt4ServerList: action.value
      }
    case types.SET_SELECTED_MT4_SERVER_LIST:
      return {
        ...state,
        seletedMt4ServerList: action.value
      }
    case types.SET_ACTION_LIST:
      return {
        ...state,
        actionList: action.value
      }
    case types.SET_SELECTED_ACTION_LIST:
      return {
        ...state,
        seletedActionList: action.value
      }
    case types.SET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.value
      }
    case types.SET_SELECTED_COUNTRIES:
      return {
        ...state,
        selectedCountries: action.value
      }
    case types.SET_CONTINENTS_LIST:
      return {
        ...state,
        continentsList: action.value
      }
    case types.SET_REBATE_STATE:
      return {
        ...state,
        rebateState: action.value
      }
    case types.SET_TRADE_STATE:
      return {
        ...state,
        tradeState: action.value
      }
    case types.SET_DEPOSITY_STATE:
      return {
        ...state,
        deposityState: action.value
      }
    case types.SET_FTD_STATE:
      return {
        ...state,
        ftdState: action.value
      }
    case types.SET_USER_AND_LEVEL_NAME:
      return {
        ...state,
        userAndLevelName: action.value
      }
    case types.SET_LEVEL_ID:
      return {
        ...state,
        levelId: action.value
      }
    case types.SET_SHOW_ALL:
      return {
        ...state,
        showAll: action.value
      }
    case types.SET_CCTRANSACTION_STATE:
      return {
        ...state,
        ccTransactionState: action.value
      }
    case types.SET_SELECTED_CREDITCARD_CHANNEL:
      return {
        ...state,
        selectedCreditCardChannel: action.value
      }
    case types.SET_SELECTED_CCTRANSACTION_TYPE:
      return {
        ...state,
        selectedTransactionType: action.value
      }
    case types.SET_SELECTED_CREDITCARD_STATUS:
      return {
        ...state,
        selectedCcStatus: action.value
      }
    case types.SET_SELECTED_SOURCEDATAVERIFY_STATUS:
      return {
        ...state,
        selectedSourceDataVerifyStatus: action.value
      }
    case types.SET_CCWITHDRAWDETAIL_STATE:
      return {
        ...state,
        ccWithdrawDetailState: action.value
      }
    case types.SET_CCWITHDRAWDETAIL_CHANNEL_LIST:
      return {
        ...state,
        ccWithdrawDetailChannelList: action.value
      }
    case types.SET_SELECTED_CCWITHDRAWDETAIL_CHANNEL_LIST:
      return {
        ...state,
        seletedCCWithdrawDetailChannelList: action.value
      }
    case types.SET_CCWITHDRAWDETAIL_STATUS_LIST:
      return {
        ...state,
        ccWithdrawDetailStatusList: action.value
      }
    case types.SET_SELECTED_CCWITHDRAWDETAIL_STATUS:
      return {
        ...state,
        seletedCCWithdrawDetailStatus: action.value
      }
    default:
      return state
  }
}