export const SET_FILTERS = "SET_FILTERS";
export const SET_DATA_SOURCE_LIST = "SET_DATA_SOURCE_LIST";
export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";
export const SET_CURRENCY_LIST = "SET_CURRENCY_LIST";
export const SET_MT4_ACCOUNT_LIST = "SET_MT4_ACCOUNT_LIST";
export const SET_SELECTED_MT4_ACCOUNT_LIST = "SET_SELECTED_MT4_ACCOUNT_LIST";
export const SET_SELECTED_COUNTRIES = "SET_SELECTED_COUNTRIES;"
export const SET_CONTINENTS_LIST = "SET_CONTINENTS_LIST;"
export const SET_TRADE_STATE = "SET_TRADE_STATE";
export const SET_DEPOSITY_STATE = "SET_DEPOSITY_STATE";
export const SET_REBATE_STATE = "SET_REBATE_STATE";
export const SET_FTD_STATE = "SET_FTD_STATE";
export const SET_USER_AND_LEVEL_NAME = "SET_USER_AND_LEVEL_NAME";
export const SET_LEVEL_ID = "SET_LEVEL_ID";
export const SET_SHOW_ALL = "SET_SHOW_ALL";
export const SET_MT4_SERVER_LIST = "SET_MT4_SERVER_LIST";
export const SET_SELECTED_MT4_SERVER_LIST = "SET_SELECTED_MT4_SERVER_LIST";
export const SET_ACTION_LIST = "SET_ACTION_LIST";
export const SET_SELECTED_ACTION_LIST = "SET_SELECTED_ACTION_LIST";
export const SET_CCTRANSACTION_STATE = "SET_CCTRANSACTION_STATE";
export const SET_CREDITCARD_CHANNEL = "SET_CREDITCARD_CHANNEL";
export const SET_SELECTED_CREDITCARD_CHANNEL = "SET_SELECTED_CREDITCARD_CHANNEL";
export const SET_CCTRANSACTION_TYPE = "SET_CCTRANSACTION_TYPE";
export const SET_SELECTED_CCTRANSACTION_TYPE = "SET_SELECTED_CCTRANSACTION_TYPE";
export const SET_CREDITCARD_STATUS = "SET_CREDITCARD_STATUS";
export const SET_SELECTED_CREDITCARD_STATUS = "SET_SELECTED_CREDITCARD_STATUS";
export const SET_SOURCEDATAVERIFY_STATUS = "SET_SOURCEDATAVERIFY_STATUS";
export const SET_SELECTED_SOURCEDATAVERIFY_STATUS = "SET_SELECTED_SOURCEDATAVERIFY_STATUS";
export const SET_CCWITHDRAWDETAIL_STATE = "SET_CCWITHDRAWDETAIL_STATE";
export const SET_CCWITHDRAWDETAIL_CHANNEL_LIST = "SET_CCWITHDRAWDETAIL_CHANNEL_LIST";
export const SET_SELECTED_CCWITHDRAWDETAIL_CHANNEL_LIST = "SET_SELECTED_CCWITHDRAWDETAIL_CHANNEL_LIST";
export const SET_CCWITHDRAWDETAIL_STATUS_LIST = "SET_CCWITHDRAWDETAIL_STATUS_LIST";
export const SET_SELECTED_CCWITHDRAWDETAIL_STATUS = "SET_SELECTED_CCWITHDRAWDETAIL_STATUS";
