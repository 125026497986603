import React, { useEffect, useState } from "react";
import { apiGetPaymentStatus } from "../../resource/index.js";
import MultiSelectBase from "../../components/searchBar/MultiSelectBase";
import Storage from "../../common/lib/storage";

const PaymentStatus = ({
  id,
  value,
  dataType,
  onChange
}) => {
  const [paymentList, setPaymentList] = useState([]);
  const regulator = Storage.getRegulator() ?? "";
  
  useEffect(() => {
    if (dataType) {
      apiGetPaymentStatus(regulator, dataType)
        .then((res) => {
          if (res.data.status === "success") {
            setPaymentList(res.data.data.paymentStatus.map(r => ({value: r.p_value, label: r.p_content})));
          }
        })
        .catch((e) => {
          setPaymentList([]);
        })
    } else {
      setPaymentList([]);
      onChange('')
    }
  }, [regulator, dataType]);

  return (
    <MultiSelectBase
      data={paymentList}
      onChange={(val) => onChange(val)}
      value={value}
      default_value="All Status"
      id={id}
      label="Payment Status"
    />
  )
}

export default PaymentStatus;